import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import React from "react";
import styled from "styled-components";

const HeaderContainer = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  height: 84px;
  align-items: center;
  justify-content: space-between;
  padding: 30px 24px;
  box-sizing: border-box;
  @media ${breakpoints.lg} {
    padding: 18px 64px;
  }
`;

const Header = ({ props, children }) => {
  return <HeaderContainer>{children}</HeaderContainer>;
};

export default Header;
