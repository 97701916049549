import breakpoints from "@vertikal/e-prospera.styles.breakpoints";
import colors, { pureColors } from "@vertikal/e-prospera.styles.colors";
import styled from "styled-components";
import bg from "./../images/footer-island.webp";
export const Background = styled.div`
  background-color: ${colors.accent.c700};
  background-image: url(${bg});
  min-height: 100vh;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center center;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  margin-top: -84px;
`;

export const Error404 = styled.div`
  max-width: 702px;
  background-color: ${pureColors.white};
  max-height: 404px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
  margin: auto 64px;
  box-sizing: border-box;
`;

export const LongTextWrap = styled.div`
  max-width: 390px;
`;

export const Logo = styled.div`
  padding: 9px;
  box-sizing: border-box;
  height: 100%;
  img {
    height: 100%;
    width: auto;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

export const Footer = styled.footer`
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.accent.c800};
  flex-direction: column;
  @media ${breakpoints.md} {
    flex-direction: row;
  }
`;

export const FooterLink = styled.a`
  color: ${colors.white};
`;
