import Box from "@vertikal/e-prospera.ui.box";
import Link from "@vertikal/e-prospera.ui.link";
import Text from "@vertikal/e-prospera.ui.text";
import { graphql } from "gatsby";
import React from "react";
import { Trans } from "react-i18next";
import GlobalSettings from "../components/GlobalSettings";
import Header from "../components/header";
import {
  Background,
  Content,
  Error404,
  LongTextWrap,
} from "../styles/404.styles";

const NotFoundPage = (props) => {
  return (
    <Background>
      <GlobalSettings />
      <Header></Header>
      <Content>
        <Error404>
          <Box marginBottom="16px">
            <Text variant="h2">
              <Trans>landing.not_found</Trans>
            </Text>
          </Box>
          <Box marginBottom="32px">
            <Text variant="h4">
              <Trans
                components={{
                  a: <Link href="/">Homepage.</Link>,
                }}
              >
                landing.try_starting_over
              </Trans>
            </Text>
          </Box>
          <LongTextWrap>
            <Box marginBottom="8px">
              <Text align="center">
                <Trans>landing.has_been_moved</Trans>
              </Text>
            </Box>
          </LongTextWrap>
          <Text color="gray">Error code: 404</Text>
        </Error404>
        {/* <Footer>
            <FooterLink color="linkWhite" href="#">
              Contact us
            </FooterLink>
            <Box marginLeft="24px" marginRight="24px">
              <FooterLink color="linkWhite" href="#">
                Terms of Service
              </FooterLink>
            </Box>
            <FooterLink color="linkWhite" href="#">
              Privacy Policy
            </FooterLink>
          </Footer> */}
      </Content>
    </Background>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
